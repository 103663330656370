import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}call-log`} component={asyncComponent(() => import('./CallRails'))}/>
      <Route path={`${match.url}analytics`} component={asyncComponent(() => import('./GoogleAnalytics/GA4'))}/>
      <Route path={`${match.url}web-leads`} component={asyncComponent(() => import('./WebLeads'))}/>
      <Route path={`${match.url}chat-leads`} component={asyncComponent(() => import('./ChatLeads'))}/>
      <Route path={`${match.url}call-alerts`} component={asyncComponent(() => import('./CallAlerts'))}/>
      {/* <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/> */}
    </Switch>
  </div>
);

export default App;
