import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import CallRails from "./CallRails";
import Leads from './Leads';
import CallAlerts from "./CallAlerts";

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  commonData: Common,
  callRails:CallRails,
  Leads: Leads,
  callAlerts:CallAlerts
});

export default reducers;
