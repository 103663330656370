import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname, authUser} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    const alerts = authUser.config && authUser.config.callAlerts ? authUser.config.callAlerts.customer_id : null
    const callAlerts = alerts == 0 ? null: alerts
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">
              <Menu.Item key="analytics">
                <Link to="/analytics"><i className="icon icon-cascader"/>
                  <IntlMessages id="sidebar.analytics"/></Link>
              </Menu.Item>
              <Menu.Item key="call-log">
                <Link to="/call-log"><i className="icon icon-phone"/>
                  <IntlMessages id="sidebar.calls"/></Link>
              </Menu.Item>
              <Menu.Item key="chat-leads">
                <Link to="/chat-leads"><i className="icon icon-chat"/>
                  <IntlMessages id="sidebar.chats"/></Link>
              </Menu.Item>
              <Menu.Item key="web-leads">
                <Link to="/web-leads"><i className="icon icon-select"/>
                  <IntlMessages id="sidebar.webleads"/></Link>
              </Menu.Item>
              {callAlerts &&
              <Menu.Item key="call-alerts">
                <Link to="/call-alerts"><i className="icon icon-all-contacts"/>
                  <IntlMessages id="sidebar.callalerts"/></Link>
              </Menu.Item>
              }
            </Menu>
          </CustomScrollbars>
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
          </div>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings, auth}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  const {authUser} = auth;
  return {navStyle, themeType, locale, pathname,authUser}
};
export default connect(mapStateToProps)(SidebarContent);

